import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const account = new Vuex.Store({
  state: {
    email: null,
    email_hash: null,
    company_name: null,
    company_details: null,
    company_vat: null,
    country: "",
    premium: false,
    card_brand: null,
    last4: "xxxx",
    renew_date: null,
    plan: null,
    billing_email: null,
    privacy_level: 1,
    limits: {},
    password: null,
    pendingUpgrade: false,
    is_otp_enabled: false,
    demographics: {
      referral_source: "",
      self_description: "",
    },
  },
  getters: {
    isPremium: (state) => {
      return state.premium;
    },
    isBusiness: (state) => {
      if (!state.plan) {
        return false;
      }
      return state.plan.kind === "enterprise" || state.plan.kind === "custom";
    },
    account: (state) => state,
    nextRoute: (state) => () => {
      // Check if the user has skipped the update modal in the last 30 days
      const skippedAt = Vue.storage.get("skipped_at");
      const shouldSkipModal =
        skippedAt !== null && Date.now() - skippedAt < 30 * 24 * 60 * 60 * 1000;

      // Check if the account data is incomplete and the update modal should be shown
      if (!shouldSkipModal) {
        if (!state.company_name || !state.country) {
          // eslint-disable-line camelcase
          return { name: "update-account" };
        }
      }
      return { name: "dashboard" };
    },
  },
  mutations: {
    setState(state, payload) {
      const originalPremiumState = state.premium;

      if (!payload) {
        return;
      }

      for (let k in payload) {
        if (!payload.hasOwnProperty(k)) continue;

        Vue.set(state, k, payload[k]);
      }

      if ("premium" in payload && payload.premium === false) {
        try {
          // We set a fake plan until the webhooks are triggereds
          const planDisplay = window.sessionStorage.getItem("planDisplay");
          if (planDisplay) {
            state.premium = true;
            state.plan = { display: planDisplay };
            state.pendingUpgrade = true;
          }
        } catch (e) {}
      }

      if (payload.email_hash && window.Fernand) {
        if (originalPremiumState !== payload.premium) {
          if (payload.premium && window.Fernand) {
            window.Fernand("init", {
              user: {
                email: payload.email,
                hash: payload.email_hash,
              },
            });
            window.Fernand("show");
          } else {
            window.Fernand("hide");
          }
        }
      }
    },
    setOtp(state, isActive) {
      state.is_otp_enabled = isActive;
    },
  },
  actions: {
    async load({ commit }) {
      try {
        const result = await Vue.http.get("account/");
        commit("setState", result.body.account);
        return result.body.account;
      } catch (error) {
        if (error.status === 403) {
          Vue.storage.remove("sessionId");
        }
        throw error;
      }
    },
    update(store, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.put("account/", payload).then(
          (response) => {
            store.commit("setState", response.body.account);
            resolve(response);
          },
          (response) => {
            reject(response);
          },
        );
      });
    },
    newPlan(store, plan) {
      store.commit("setState", {
        premium: true,
        plan: plan,
      });
    },
    async connect(store, payload) {
      Vue.storage.set("sessionId", payload);
      await store.dispatch("load");
    },
    disconnect(store) {
      Vue.http.post("auth/logout");
      Vue.storage.remove("sessionId");
    },
  },
});

export default account;
