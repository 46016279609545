<template>
  <div id="app" v-bind:class="{ 'app--has-alert': isAlertPresent() }">
    <div
      class="alert alert-sticky alert--error"
      v-if="
        account.lock_reason &&
        $route.name !== 'domain-guides' &&
        $route.name !== 'update-account' &&
        $route.name !== 'validate-email'
      "
    >
      <template v-if="account.lock_reason === 'bounced'">
        <p>
          The email you provided for your account is not valid. Please go to
          your account settings and update your email.
        </p>
      </template>
      <template v-else-if="account.lock_reason === 'email'">
        <p>
          We've just sent you an email to validate your account. Please check
          your inbox and spam folder and click on the provided link to fully
          enable your account.
        </p>
        <a
          href="javascript:;"
          v-on:click="resendValidation"
          class="button"
          v-if="resendAllowed"
          >Re-send confirmation email
          {{ !isProd() ? "CHECK API LOGS FOR LINK" : "" }}</a
        >
      </template>
      <template v-else-if="account.lock_reason === 'ban'">
        <p>
          Your account has been locked because your usage infriged our Terms of
          Service. If you don't agree, please contact us.
        </p>
      </template>
      <template v-else-if="account.lock_reason === 'domains'">
        <p>
          <strong
            >Your account is locked because you are over your plan's
            limits</strong
          >. Consider deleting domain/aliases or upgrading to lift the lock.
          <router-link :to="{ name: 'payment' }" class="button"
            >Upgrade</router-link
          >
        </p>
      </template>
      <template v-else-if="account.lock_reason === 'removed'">
        <p>
          Your account has been marked as removed and will be definitely removed
          soon. If you think this is an issue, please contact us.
        </p>
      </template>
    </div>
    <div class="alert alert-sticky" v-if="$route.query.disconnected">
      <p>Your session has expired. Please reconnect if you need to continue.</p>
    </div>
    <div class="alert alert-sticky" v-if="$route.query.deleted">
      <p>Your account has been successfully deleted.</p>
    </div>
    <div
      class="alert alert-sticky alert--success"
      v-if="$route.query.emailValidated"
    >
      <p>Your email has been successfully validated!</p>
    </div>
    <div>
      <header class="header__dashboard" v-if="isHeaderVisible()">
        <router-link :to="{ name: 'dashboard' }" class="logo">
          <svg width="28" height="29" xmlns="http://www.w3.org/2000/svg">
            <g fill="#2FBEFF" fill-rule="evenodd">
              <path
                d="M17.6 25.07a5.43 5.43 0 0 1-7.73 0 6.9 6.9 0 0 0-3.93-1.94v1.51c1.04.2 2.05.7 2.86 1.5a6.93 6.93 0 0 0 4.93 2.05c1.86 0 3.61-.73 4.93-2.05a5.42 5.42 0 0 1 2.7-1.46v-1.53c-1.38.23-2.7.86-3.77 1.92"
              />
              <path
                d="M17.6 21.25a5.43 5.43 0 0 1-7.73 0 6.99 6.99 0 0 0-9.87 0l1.07 1.07a5.47 5.47 0 0 1 7.73 0 6.93 6.93 0 0 0 4.93 2.05c1.86 0 3.61-.73 4.93-2.05a5.47 5.47 0 0 1 7.73 0l1.07-1.07a6.99 6.99 0 0 0-9.87 0"
              />
              <path
                d="M13.78 8.46l-5.54 3-.02-5.88h1.95l7.2-.03h1.95l.01 5.87-5.55-2.96zm2.02-6.9L15.81 4l-4.09.01V1.57h4.08zm5.1 10.7l-.03-8.27h-3.5L17.36 0l-7.2.02v4h-3.5l.02 8.27-3.25 1.76 1.08 3.9a6.8 6.8 0 0 1 1.63.08l-.88-3.2L13 10.65l.02 10.04h1.56l-.03-10.04 7.77 4.14-.84 3.12a6.8 6.8 0 0 1 1.58.1L24.16 14l-3.27-1.74z"
              />
            </g>
          </svg>
          <span class="staging" v-if="!isProd()">
            DEV
            <button @click="switchPlan">
              <span v-if="account.plan">{{ account.plan.display }}</span>
              <span v-else>Free</span>
            </button>
          </span>
        </router-link>
        <nav>
          <ul>
            <li>
              <router-link :to="{ name: 'dashboard' }" exact
                >Dashboard</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'billing' }" exact
                >Billing & Receipts</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'settings' }" exact
                >Account Info</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'api_keys' }" exact
                >API access</router-link
              >
            </li>
            <li>
              <a
                href="https://help.improvmx.com"
                title="Read our articles on our knowledge base"
                target="_blank"
                >Help Center</a
              >
            </li>
          </ul>
        </nav>
        <div class="footer__wrapper">
          <div class="user__wrapper" v-if="account.email">
            <span class="user__label u__text--small">Logged in as</span>
            <span class="user__email">{{ account.email }}</span>
            <button class="user__logout" v-on:click="logout">Logout</button>
            <div class="premium__account" v-if="account.premium">
              ⚡ {{ account.plan.display }} plan
            </div>
            <router-link
              :to="{ name: 'payment' }"
              class="premium__upgrade"
              v-else
              >Upgrade your account</router-link
            >
          </div>
          <ul class="legal__links">
            <li>
              <a href="https://improvmx.com/terms-of-service/" target="_blank"
                >ToS</a
              >
            </li>
            <li>
              <a href="https://improvmx.com/privacy-policy/" target="_blank"
                >Privacy</a
              >
            </li>
            <li>
              <a href="https://status.improvmx.com/" target="_blank"
                >Server Status</a
              >
            </li>
            <li>
              <a href="https://improvmx.com/contact/" target="_blank"
                >Contact</a
              >
            </li>
          </ul>
        </div>
      </header>
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<style>
.alert-sticky {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 9999;
  background: #ff7878;
}
.alert--error {
  background: #ff7878;
}
.alert--success {
  background: #51df3e;
}
.app--has-alert main {
  padding-top: 60px;
}
.app--has-alert header {
  padding-top: 110px;
}
</style>

<script>
import AccountStore from "@/stores/Account";
import Environment from "@/plugins/Environment";

// In dev, allow resending the confirmation email immediately.
export const RESEND_CONFIRMATION_TIMEOUT =
  Environment.getEnv() === Environment.PROD ? 30000 : 100;

export default {
  data() {
    return {
      account: AccountStore.state,
      resendAllowed: false,
    };
  },
  created() {
    if (this.$storage.get("sessionId", null)) {
      AccountStore.dispatch("load").catch((response) => {
        if (response.status === 403) {
          this.$router.push({ name: "login" });
        } else if (response.status === 449) {
          this.$router.push({
            name: "2FA",
            params: { token: this.$storage.get("sessionId") },
          });
        }
      });
    }

    setTimeout(() => {
      this.resendAllowed = true;
    }, RESEND_CONFIRMATION_TIMEOUT);
  },
  metaInfo: {
    title: "ImprovMX — Free email forwarding",
    titleTemplate: "%s",
  },
  methods: {
    isProd() {
      return Environment.getEnv() === Environment.PROD;
    },
    logout() {
      AccountStore.dispatch("disconnect");
      this.$router.push({ name: "login" });
    },
    isHeaderVisible() {
      return (
        [
          "login",
          "login-dns",
          "upgrade",
          "upgrade-details",
          "validate-email",
          "update",
          "2FA",
          "change-password",
          "update-account",
        ].indexOf(this.$router.currentRoute.name) === -1
      );
    },
    resendValidation() {
      this.resendAllowed = false;
      setTimeout(() => {
        this.resendAllowed = true;
      }, RESEND_CONFIRMATION_TIMEOUT);

      this.$http.post("account/validate/").then(
        (response) => {},
        (response) => {
          alert("An error occured!");
        },
      );
    },
    isAlertPresent() {
      if (this.account.locked) {
        return true;
      } else if (this.$route.query.disconnected) {
        return true;
      } else if (this.$route.query.deleted) {
        return true;
      } else if (this.$route.query.emailValidated) {
        return true;
      }

      return false;
    },
    switchPlan() {
      if (this.isProd()) {
        return false;
      }

      if (this.account.plan) {
        AccountStore.commit("setState", {
          plan: null,
          premium: false,
          cancels_on: null,
          card_brand: null,
        });
      } else {
        AccountStore.commit("setState", {
          plan: {
            aliases_limit: 10000,
            daily_quota: 100000,
            display: "Business - $249",
            domains_limit: 10000,
            kind: "enterprise",
            name: "enterprise249",
            price: 249,
            yearly: false,
          },
          premium: true,
          cancels_on: null,
          card_brand: "MasterCard",
        });
      }
    },
  },
};
</script>
