import Vue from "vue";

const Environment = function () {};
Environment.PROD = "prod";
Environment.DEV = "dev";
Environment.env = null;

Environment.getEnv = function () {
  if (Environment.env === null) {
    if (window.location.host === "app.improvmx.com") {
      this.setEnv(this.PROD);
    } else {
      this.setEnv(this.DEV);
    }
  }
  return Environment.env;
};
Environment.setEnv = function (env) {
  console.log("Working on " + env.toUpperCase() + " environment."); // eslint-disable-line no-console
  this.env = env;
  switch (env) {
    case this.PROD:
      window.stripe_api_key =
        "pk_live_51QWhsf02BjBQ0gEjuOIYAmf2gW5PhUg9A0z9cfrHetECKGF4CORPKd7AFrNTFUTQtSj5tUd1BDA9Y1orX1kZZkRu00zqC8V8GX";
      break;
    default:
      window.stripe_api_key =
        "pk_test_51Qq7YrP3Ll2KV59gnlshwotPqVT3lzixORfzM0Bxxgj9kK7AnXY5kATKEJdFpQZrOoZkCtJQGcLVwnUGeiZrLc9i00RRMWFzHl";
      break;
  }
};

Environment.testEndpoint = async function (baseUrl) {
  return Vue.http.get(baseUrl + "/ping").then(
    (response) => {
      if (response.data.toLowerCase().indexOf("improvmx") > -1) {
        return true;
      } else {
        return false;
      }
    },
    (response) => {
      return false;
    },
  );
};
Environment.getAPIUrl = async function () {
  const customUrl = Vue.storage.get("api_url");
  if (customUrl) {
    // Checking that the endpoint is a valid Reachful API server
    let baseUrl = customUrl.substr(0, customUrl.indexOf("/", 10));
    if (await Environment.testEndpoint(baseUrl)) {
      console.info("Using custom URL: ", customUrl); // eslint-disable-line no-console
      this.setEnv(Environment.DEV);
      return customUrl;
    }
  }
  if (this.getEnv() === this.PROD) {
    return "https://api.improvmx.com/v3/";
  } else {
    console.info("Using DEV http://127.0.0.1:5001/v3/"); // eslint-disable-line no-console
    return "http://127.0.0.1:5001/v3/";
  }
};

export default Environment;
