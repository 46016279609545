import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueResource from "vue-resource";
import Environment from "./plugins/Environment";
import VueStorage from "./plugins/Storage";
import Meta from "vue-meta";
import Moment from "moment";

import "./css/tailwind.css";

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueStorage);
Vue.use(Meta);

// Adds auth parameters
// Redirect to homepage in case of disconnected
Vue.http.interceptors.push((request, next) => {
  if (Vue.storage.get("sessionId", null) !== null) {
    request.headers.set(
      "Authorization",
      "Bearer " + Vue.storage.get("sessionId"),
    );
  }
  next((response) => {
    if (response.status === 401) {
      Vue.storage.remove("sessionId");
      if (router.currentRoute.name !== "login") {
        router.push({ name: "login", query: { disconnected: true } });
      }
    } else if (response.status === 429) {
      alert(
        "You have been rate limited. Please wait a while before continuing.",
      );
    }
  });
});

Environment.getAPIUrl().then((url) => {
  Vue.http.options.root = url;
  new Vue({
    router,
    render: (h) => h(App),
    data() {
      return {
        activeDomain: null,
      };
    },
    methods: {
      setActiveDomain(domain) {
        this.activeDomain = domain;
      },
      getActiveDomain() {
        return this.activeDomain;
      },
    },
  }).$mount("#app");
});

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.filter("datetime", (date, format) => {
  if (!date) {
    return date;
  }

  if (!format) {
    format = "YYYY-MM-DD";
  }

  return Moment.utc(date).format(format);
});

Vue.filter("relative", (date, relative) => {
  let current = Moment.utc(date);
  if (relative) {
    if (date === relative) {
      return "+0 seconds";
    }

    let origin = Moment.utc(relative);
    let duration = Moment.duration(current.diff(origin));
    let seconds = duration.asSeconds();

    if (seconds < 90) {
      return "+" + seconds + " seconds";
    } else if (seconds < 3600) {
      return "+" + (seconds / 60).toFixed(0) + " minutes";
    } else {
      return "+" + (seconds / 60 / 60).toFixed(0) + " hours";
    }
  }

  return current.fromNow();
});

Vue.filter("dollars", (amount) => {
  if (!isNaN(parseInt(amount))) {
    amount = parseFloat(amount);
  }

  if (typeof amount === "number") {
    amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    if (amount.substr(-3) === ".00") {
      amount = amount.slice(0, -3);
    }
  }

  return "$" + amount;
});
