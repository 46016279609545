import Vue from "vue";
import Router from "vue-router";
import AccountStore from "@/stores/Account";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
    },
    {
      path: "/account/update",
      name: "update-account",
      component: () => import("@/views/UpdateDemogprahics.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/Login.vue"),
    },
    {
      path: "/login/dns",
      name: "login-dns",
      component: () => import("@/views/login/Dns.vue"),
    },
    {
      path: "/auth/:token",
      name: "2FA",
      beforeEnter: (to, from, next) => {
        Vue.http.post("auth/validate/" + to.params.token).then(
          (response) => {
            AccountStore.dispatch("connect", response.body.token).then(
              () => {
                next(AccountStore.getters.nextRoute());
              },
              () => next(),
            );
          },
          (response) => {
            if (response.status !== 449) {
              return next({ name: "login", query: { disconnected: true } });
            }
            next(); // In that case we show the 2FA
          },
        );
      },
      component: () => import("@/views/login/2FA.vue"),
    },
    {
      path: "/auth/:token/change-password",
      name: "change-password",
      beforeEnter: (to, from, next) => {
        Vue.http.post("auth/validate/" + to.params.token).then(
          (response) => {
            Vue.storage.set("sessionId", response.body.token);
            next();
          },
          (response) => {
            next({ name: "login", query: { disconnected: true } });
          },
        );
      },
      component: () => import("@/views/login/Change.vue"),
    },
    {
      path: "/validate/:token",
      name: "validate-email",
      component: () => import("@/views/Validate.vue"),
    },
    {
      path: "/update/:token",
      name: "update-email",
      beforeEnter: (to, from, next) => {
        Vue.http.post("account/validate/" + to.params.token).then(
          (response) => {
            AccountStore.dispatch("connect", response.body.token);
            next({ name: "dashboard", query: { emailValidated: 1 } });
          },
          (response) => {
            let error = "An error occured.";
            if (response.body.errors) {
              error =
                response.body.errors[Object.keys(response.body.errors)[0]][0];
            }
            alert(error);
          },
        );
      },
    },
    {
      path: "/domains/:domain",
      name: "domain-details",
      component: () =>
        import(
          /* webpackChunkName: "group-domain" */ "@/views/domain/DomainDetails.vue"
        ),
      children: [
        {
          path: "/domains/:domain/aliases",
          name: "domain-aliases",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DomainAliases.vue"
            ),
        },
        {
          path: "/domains/:domain/dns",
          name: "dns-settings",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DnsSettings.vue"
            ),
        },
        {
          path: "/domains/:domain/guides",
          name: "domain-guides",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DomainGuide.vue"
            ),
        },
        {
          path: "/domains/:domain/credentials",
          name: "domain-credentials",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DomainCredentials.vue"
            ),
        },
        {
          path: "/domains/:domain/logs",
          name: "domain-logs",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DomainLogs.vue"
            ),
        },
        {
          path: "/domains/:domain/settings",
          name: "domain-settings",
          component: () =>
            import(
              /* webpackChunkName: "group-domain" */ "@/views/domain/DomainSettings.vue"
            ),
        },
      ],
    },
    {
      path: "/account/settings",
      name: "settings",
      component: () =>
        import(
          /* webpackChunkName: "group-account" */ "@/views/account/Settings.vue"
        ),
    },
    {
      path: "/account/billing",
      name: "billing",
      component: () =>
        import(
          /* webpackChunkName: "group-account" */ "@/views/account/Billing.vue"
        ),
    },
    {
      path: "/account/payment/change",
      name: "payment-change",
      component: () =>
        import(
          /* webpackChunkName: "group-account" */ "@/views/account/UpdateCard.vue"
        ),
    },
    {
      path: "/account/downgrade",
      name: "downgrade",
      component: () =>
        import(
          /* webpackChunkName: "group-account" */ "@/views/account/Downgrade.vue"
        ),
    },
    {
      path: "/account/payment/success",
      name: "payment-success",
      component: () =>
        import(
          /* webpackChunkName: "group-payment" */ "@/views/payment/Success.vue"
        ),
    },
    {
      path: "/account/payment",
      component: () =>
        import(
          /* webpackChunkName: "group-payment" */ "@/views/account/AccountPayment.vue"
        ),
      children: [
        {
          path: "",
          name: "payment",
          meta: { basename: "payment" },
          component: () =>
            import(
              /* webpackChunkName: "group-payment" */ "@/views/payment/Plans.vue"
            ),
        },
        {
          path: ":plan",
          name: "payment-details",
          meta: { basename: "payment" },
          component: () =>
            import(
              /* webpackChunkName: "group-payment" */ "@/views/payment/Details.vue"
            ),
        },
      ],
    },
    {
      path: "/account/upgrade/:token",
      component: () =>
        import(
          /* webpackChunkName: "group-account" */ "@/views/account/AccountUpgrade.vue"
        ),
      meta: { basename: "upgrade" },
      beforeEnter: (to, from, next) => {
        Vue.http
          .get("account/", {
            headers: { Authorization: "Custom " + to.params.token },
          })
          .then((response) => {
            AccountStore.commit("setState", response.body.account);
            next();
          });
      },
      children: [
        {
          path: "",
          name: "upgrade",
          meta: { basename: "upgrade" },
          component: () =>
            import(
              /* webpackChunkName: "group-payment" */ "@/views/payment/Plans.vue"
            ),
        },
        {
          path: ":plan",
          name: "upgrade-details",
          meta: { basename: "upgrade" },
          component: () =>
            import(
              /* webpackChunkName: "group-payment" */ "@/views/payment/Details.vue"
            ),
        },
      ],
    },
    {
      path: "/api",
      name: "api_keys",
      component: () => import("@/views/Api.vue"),
    },
    {
      path: "/import",
      name: "bulk_import",
      component: () => import("@/views/account/BulkImport.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(function (to, from, next) {
  if (Vue.storage.get("sessionId", null) === null) {
    if (
      [
        "login",
        "2FA",
        "upgrade",
        "update-email",
        "validate-email",
        "change-password",
        "payment-success",
        "settings",
      ].indexOf(to.name) === -1 &&
      to.name.indexOf("upgrade") === -1
    ) {
      next("/login");
    }
  }

  return next();
});

export default router;
